import api from "./api";
import AuthService from "./auth.service";

var apiData = {};

apiData.getClient = (clientId, userId = 0, userExcept = false) => {
	return new Promise((resolve, reject) => {
		api.get(
			`/client/${clientId}?userException=${userExcept}&userId=${userId}`
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.getAdvertisingTypes = () => {
	return new Promise((resolve, reject) => {
		api.get("/shared/advertisingtypes")
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.getBrands = (searchtext) => {
	return new Promise((resolve, reject) => {
		api.get(searchtext ? `/shared/brands/${searchtext}` : "/shared/brands")
			.then((response) => {
				// console.log('RESPONSE BRANDS: ', response.data)
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.getCategories = (searchtext) => {
	return new Promise((resolve, reject) => {
		api.get(
			searchtext
				? `/shared/categories/${searchtext}`
				: "/shared/categories"
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.getCountries = (searchtext) => {
	return new Promise((resolve, reject) => {
		api.get(
			searchtext ? `/shared/countries/${searchtext}` : "/shared/countries"
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.getMediaTypes = () => {
	return new Promise((resolve, reject) => {
		api.get("/shared/mediatypes")
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.getSubCategories = (searchtext) => {
	return new Promise((resolve, reject) => {
		api.get(
			searchtext
				? `/shared/subcategories/${searchtext}`
				: "/shared/subcategories"
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.getTopics = (searchtext) => {
	return new Promise((resolve, reject) => {
		api.get(searchtext ? `/shared/topics/${searchtext}` : "/shared/topics")
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.getTypes = () => {
	return new Promise((resolve, reject) => {
		api.get("/shared/types")
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.getSubscriptions = () => {
	return new Promise((resolve, reject) => {
		api.get("/shared/subscriptions")
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.getAnalytics = (filters) => {
	return new Promise((resolve, reject) => {
		api.post(`/analytics/getData`, filters)
			.then((response) => {
				// console.log('RESPONSE ANALYTICS: ', response.data)
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.userLogDetails = (body) => {
    return new Promise((resolve, reject) => {
        api.post(`/AdminMonitoring/manageuserlogdetails`, body)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

apiData.getGridData = (
	filters,
	pagenumber,
	pagesize,
	filterField,
	filterValue,
	sortExp
) => {
	return new Promise((resolve, reject) => {
		api.post(
			`/analytics/getgriddata?pageNumber=${pagenumber}&pageSize=${pagesize}&filterField=${filterField}&filterValue=${filterValue}&sortExp=${sortExp}`,
			filters
		)
			.then((response) => {
				// console.log('RESPONSE ANALYTICS: ', response.data)
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.getDrillData = (filters) => {
	return new Promise((resolve, reject) => {
		api.post(`/analytics/drilldata`, filters)
			.then((response) => {
				// console.log('RESPONSE ANALYTICS: ', response.data)
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.getExport = (definition) => {
	return new Promise((resolve, reject) => {
		const config = {
			responseType: "blob" // Set the responseType to blob
		};

		api.post(`/analytics/exportdata`, definition, config)
			.then((response) => {
				// console.log('RESPONSE ANALYTICS: ', response.data)
				resolve(response);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.createFlipbook = (details) => {
	return new Promise((resolve, reject) => {
		api.post(`/Flipbook`, details)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
apiData.getFlipbook = (bookID) => {
	return new Promise((resolve, reject) => {
		api.get(`/Flipbook/byId/${bookID}`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
apiData.updateFlipbook = (id, details) => {
	return new Promise((resolve, reject) => {
		api.put(`/Flipbook/${id}`, details)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
apiData.shareFlipbook = (details) => {
	return new Promise((resolve, reject) => {
		api.post(`/Flipbook/share`, details)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
apiData.deleteFlipbook = (id) => {
	return new Promise((resolve, reject) => {
		api.delete(`/Flipbook/${id}`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
apiData.getAllFlipbooks = (userId = 0) => {
	return new Promise((resolve, reject) => {
		api.get(`/Flipbook/Flipbooks/${userId}`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
apiData.viewFlipbook = (id) => {
	return new Promise((resolve, reject) => {
		api.get(`/Flipbook/${id}/view`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.getAlert = (pid) => {
	return new Promise((resolve, reject) => {
		api.get(`/Alerts/load?pid=${pid}`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.getUsers = (searchtext) => {
	return new Promise((resolve, reject) => {
		api.get(
			searchtext
				? `/users/usersByFilter/${searchtext}`
				: `/users/usersByFilter`
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
apiData.updateUser = (userDetails) => {
	return new Promise((resolve, reject) => {
		api.put(`/users/${userDetails.id}`, userDetails)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
apiData.createUser = (userDetails) => {
	return new Promise((resolve, reject) => {
		api.post("/users", userDetails)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.getUserClients = (id) => {
	return new Promise((resolve, reject) => {
		api.get(`/Users/${id}/clients`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
apiData.addUserClient = (userid, clientId) => {
	return new Promise((resolve, reject) => {
		api.post(`/users/${userid}/userclient/${clientId}`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
apiData.deleteUser = (userid) => {
	return new Promise((resolve, reject) => {
		api.delete(`/users/${userid}`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.deleteUserClient = (userid, clientId) => {
	return new Promise((resolve, reject) => {
		api.delete(`/users/${userid}/userclient/${clientId}`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
apiData.getClients = (searchtext) => {
	return new Promise((resolve, reject) => {
		api.get(
			searchtext
				? `/client/clientsByFilter/${searchtext}`
				: `/client/clientsByFilter`
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
apiData.updateClient = (clientDetails) => {
	return new Promise((resolve, reject) => {
		api.put(`/client/${clientDetails.id}`, clientDetails)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.createClient = (clientDetails) => {
	return new Promise((resolve, reject) => {
		api.post("/client", clientDetails)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
apiData.deleteClient = (clientid) => {
	return new Promise((resolve, reject) => {
		api.delete(`/client/${clientid}`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
apiData.updateConfig = (clientDetails) => {
	return new Promise((resolve, reject) => {
		api.put(`/client/${clientDetails.id}/config`, clientDetails)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.createConfig = (clientDetails) => {
	return new Promise((resolve, reject) => {
		api.post(`/client/${clientDetails.clientId}/config`, clientDetails)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.updateConfig = (clientDetails) => {
	return new Promise((resolve, reject) => {
		api.put(
			clientDetails.userId
				? `/users/${clientDetails.userId}/userclient/${clientDetails.clientId}/config/${clientDetails.configId}`
				: `/client/${clientDetails.clientId}/config`,
			clientDetails
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.deleteConfig = (clientDetails) => {
	return new Promise((resolve, reject) => {
		api.delete(
			clientDetails.userId
				? `/users/${clientDetails.userId}/userclient/${clientDetails.clientId}/config/${clientDetails.configId}`
				: `/client/${clientDetails.clientId}/config/${clientDetails.configId}`
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

///To get all alerts by client
apiData.getAlertsClient = (clientId) => {
	return new Promise((resolve, reject) => {
		api.get(`/Alerts/${clientId}`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

// To get schedued alerts
apiData.getScheduledAlerts = (schedule) => {
	return new Promise((resolve, reject) => {
		api.get(`/Alerts/getscheduledalerts/${schedule}`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

// To Delete Alert recoard from a table
apiData.deleteConfigAlert = (clientId) => {
	return new Promise((resolve, reject) => {
		api.delete(`/Alerts/${clientId}`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.deleteScheduledAlerts = (schedule) => {
	return new Promise((resolve, reject) => {
		api.delete(`/Alerts/getscheduledalerts/${schedule}`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

// Submit Alerts
apiData.addAlert = (body) => {
	return new Promise((resolve, reject) => {
		api.post(`/Alerts`, body)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.updateAlert = (clientId, body) => {
	return new Promise((resolve, reject) => {
		api.put(`/Alerts/${clientId}`, body)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.getAlertshistory = (ClientID) => {
	return new Promise((resolve, reject) => {
		api.get(`/Alerts/history/${ClientID}`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

///To get all Newsletters by client
// apiData.getNewslettersClient = (clientId) => {
// 	return new Promise((resolve, reject) => {
// 		api.get(`/Newsletters/${clientId}`)
// 			.then((response) => {
// 				resolve(response.data);
// 			})
// 			.catch((error) => {
// 				reject(error);
// 			});
// 	});
// };

apiData.getUserSandbox = () => {
	return new Promise((resolve, reject) => {
		const user = AuthService.getCurrentUser().userId;
		console.log(user)
		api.get(`/analytics/userSandbox/${AuthService.getCurrentUser().userId}`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.postUserSandbox = (items) => {
	return new Promise((resolve, reject) => {
		api.post(`/analytics/userSandbox/${AuthService.getCurrentUser().userId}`, items)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	})
}

apiData.deleteUserSandbox = (id) => {
	return new Promise((resolve, reject) => {
		api.delete(`/analytics/userSandbox/${AuthService.getCurrentUser().userId}/${id}`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.clientSentiment = (itemId, newSentiment) => {
	const cliendId = JSON.parse(sessionStorage.getItem('selectedClient')).id;
	return new Promise((resolve, reject) => {
		api.post(`/analytics/clientSentiment`, {
			clientId: cliendId,
			dataId: itemId,
			sentiment: newSentiment,
			createdUserId: AuthService.getCurrentUser().userId
		})
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

apiData.deleteClientSentiment = (itemId) => {
	const cliendId = JSON.parse(sessionStorage.getItem('selectedClient')).id;
	return new Promise((resolve, reject) => {
		api.delete(`/analytics/clientSentiment/${cliendId}/${itemId}`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.getUserCustom = () => {
	return new Promise((resolve, reject) => {
		api.get(`/analytics/userCustom/${AuthService.getCurrentUser().userId}`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

apiData.postUserCustom = (fields) => {
	return new Promise((resolve, reject) => {
		api.post(`/analytics/userCustom/${AuthService.getCurrentUser().userId}`, fields)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export default apiData;
