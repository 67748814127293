import api from "./api";
import TokenService from "./token.service";
import apiData from "./api_data";

const register = (username, email, password) => {
  return api.post("/auth/signup", {
    username,
    email,
    password
  });
};

const login = (username, password) => {
  return api
    .post("/users/authenticate", {
      username,
      password
    })
    .then((response) => {
      if (response.data.accessToken) {
        TokenService.setUser(response.data);
      }

      return response.data;
    });
};

const logout = () => {
  callLogoutApi();
};

const getCurrentUser = () => {
  return JSON.parse(sessionStorage.getItem("user"));
};

const callLogoutApi = () => {
  const UserTabs = JSON.parse(sessionStorage.getItem("UserTabs"));
  UserTabs[UserTabs.length - 1].endDateTime = new Date();
  const user = TokenService.getUser();

  const userLogDetails = {
    userId: user.userId,
    loginDate: JSON.parse(sessionStorage.getItem("LoginDate")),
    logoutDate: new Date(),
    user_Tabs: UserTabs,
  }

  apiData
    .userLogDetails(
      userLogDetails
    )
    .then((response) => {
        ClearAndLogout();
    })
    .catch((error) => {
      ClearAndLogout();
    });
}

const ClearAndLogout = () =>{
  TokenService.removeUser();
  sessionStorage.clear();
  localStorage.clear();
  window.location.reload();
}

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
  callLogoutApi,
};

export default AuthService;
