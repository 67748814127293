import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserSandbox } from './sandbox';
import apiData from '../../services/api_data';
import api from '../../services/api';

export const postUserSandbox = createAsyncThunk(
    'sandbox/post',
    async (_, { getState, dispatch }) => {
        const { tableRows, tableSelectionModel } = getState().data;
        const data = await apiData.postUserSandbox(tableSelectionModel);

        const removedIds = data.map(e => e.id);
        const filteredRows = tableRows.filter(e => !removedIds.includes(e.id));
        dispatch(setTableRows(filteredRows));
        dispatch(getUserSandbox());
        return data;
    }
);

export const getDataRows = createAsyncThunk(
    'data/getRows',
    async (_, { getState, dispatch }) => {
        dispatch(setLoading(true));
        dispatch(setPaginationMode("server"));

        const dataFilter = JSON.parse(sessionStorage.getItem("dataFilter"));
        const { page, filterModel, sortModel } = getState().data;
        const { sandboxedItems } = getState().sandbox;

        let filtField = "";
        let filtValue = "";

        const sortExp = sortModel.length > 0
            ? `${sortModel[0].field} ${sortModel[0].sort}`
            : "";

        const filt = Object.values(filterModel);

        if (filt.length > 0) {
            if (filt[0][0].value !== "" && filt[0][0].value) {
                if (filt[0][0].value.length > 5) {
                    filtField = filt[0][0].columnField;
                    filtValue = filt[0][0].value.toUpperCase();
                }
            }
        }

        const response = await apiData.getGridData(
            JSON.stringify(dataFilter),
            page + 1,
            10,
            filtField,
            filtValue,
            sortExp
        );

        let { rowCount, filteredList } = response;
        sessionStorage.setItem("rowCount", rowCount)
         // Dispatch a custom event to notify other components
        const storageEvent = new Event('storage');
        window.dispatchEvent(storageEvent);
        const sandboxedIds = sandboxedItems.map(e => e.id);
        filteredList = filteredList.filter(e => !sandboxedIds.includes(e.id));

        // filteredList.forEach(e => {
        //     if (e.sentiment.includes("*"))
        //         e.sentiment = e.sentiment.replaceAll("*", "");
        // });

        dispatch(setLoading(false));
        return {
            filteredList: filteredList,
            rowCount: rowCount
        };
    }
);

export const dataSlice = createSlice({
    name: 'dataSlice',
    initialState: {
        tableRows: [],
        rowCount: 0,
        tableSelectionModel: [],
        paginationMode: "client",
        filterModel: [],
        sortModel: [],
        page: 0,
        loading: true,
        selectionModel: [],
    },
    reducers: {
        setTableRows: (state, action) => {
            state.tableRows = action.payload;
        },
        setDataTableSelection: (state, action) => {
            state.tableSelectionModel = action.payload;
        },
        setPaginationMode: (state, action) => {
            state.paginationMode = action.payload;
        },
        setFilterModel: (state, action) => {
            state.filterModel = action.payload;
        },
        setSortModel: (state, action) => {
            state.sortModel = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setSelectionModel: (state, action) => {
            state.selectionModel = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(postUserSandbox.rejected, (_, error) => console.error('REJECTED postUserSandbox: ', error))
        builder.addCase(getDataRows.rejected, (_, error) => console.error('REJECTED getDataRows: ', error))
            .addCase(getDataRows.fulfilled, (state, action) => {
                state.rowCount = action.payload.rowCount;
                state.tableRows = action.payload.filteredList;
            })
    },
});

export const { setTableRows, setDataTableSelection, setPaginationMode, setFilterModel, setSortModel, setPage, setLoading, setSelectionModel, tableSelectionModel } = dataSlice.actions;

export default dataSlice.reducer;