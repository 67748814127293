import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { sandboxSlice } from './slices/sandbox';
import { dataSlice } from './slices/data';

const rootReducer = combineReducers({
  sandbox: sandboxSlice.reducer,
  data: dataSlice.reducer,
});

export default configureStore({
  reducer: rootReducer
});