import React from 'react';
import { Button, makeStyles } from '@material-ui/core';

export default function MainButton(props) {
    return (
        <Button
            type="submit"
            variant="contained"
            className={useStyles().submit}
            style={props.style}
            onClick={() => props.onClick && props.onClick()}>
            {props.text}
        </Button>
    )
}

const useStyles = makeStyles((theme) => ({
    submit: {
        width: 347,
        height: 50,
        backgroundColor: '#0E102C',
        fontSize: 16,
        fontFamily: 'Poppins-Medium',
        color: '#fff',
        borderRadius: 7,
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: '#0E102C',
        },
    }
}));