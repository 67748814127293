import React, { useState, useEffect, useRef } from 'react';
import { Checkbox, TextField, Box, IconButton } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import emitter from 'tiny-emitter/instance';
import _ from 'lodash';

const SelectMultipleSearch = ({ values, onValuesChange, placeholder, field, userSelectedValues = [] }) => {
    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState(userSelectedValues);
    const [search, setSearch] = useState('');
    const [filteredOptions, setFilteredOptions] = useState([]);
    const isFirstRender = useRef(true);

    React.useEffect(() => {
        emitter.on('resetAll', reset);
        return (() => emitter.off('resetAll', reset));
    }, []);

    const reset = () => {
        setSelectedOptions([]);
    }

    React.useEffect(() => {
        if (values && values.length) {
            let newOptions = [];
            values.forEach(v => {
                if (v)
                    if (typeof (v) === 'object' && v.itemName) {
                        newOptions.push({ value: v.itemName, label: v.itemName })
                    }
                    else if (typeof (v) === 'string')
                        newOptions.push({ value: v, label: v });
            });
            setOptions(newOptions);
            setFilteredOptions(newOptions);
        }
    }, [values]);

    React.useEffect(() => {
        const optionsIndexes = [];
        selectedOptions.forEach(opt => {
            let index;
            index = values.map(e => {
                if (typeof e === 'object')
                    return e?.itemName;
                else
                    return e;
            }).indexOf(opt.value);
            if (index !== -1)
                optionsIndexes.push(index);
        });
        onValuesChange(optionsIndexes);
    }, [selectedOptions]); //eslint-disable-line

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        searchValues();
    }, [search]);

    const searchValues = _.debounce(() => {
        setFilteredOptions(
            options.filter(option => option.value.toLowerCase().includes(search.toLowerCase()))
        );
    }, 500);

    const debouncedSearch = _.debounce(searchValues, 1500)

    return (
        options &&
        <Autocomplete
            multiple
            options={filteredOptions}
            disableClearable={true}
            onChange={event => {
                const index = selectedOptions.map(e => e.value).indexOf(event.target.textContent);
                if (!event.target.textContent.length)
                    return;
                if (index === -1)
                    setSelectedOptions([...selectedOptions, { value: event.target.textContent, label: event.target.textContent }]);
                else {
                    let newSelectedOptions = [...selectedOptions];
                    newSelectedOptions.splice(index, 1);
                    setSelectedOptions(newSelectedOptions);
                }
            }}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option, value) => option.value === value.value}
            defaultValue={[options[0]]}
            value={selectedOptions}
            disableCloseOnSelect={true}
            renderInput={(params) => {
                // console.log('PARAMS INPUT: ', params);
                return (
                    <TextField
                        {...params}
                        variant="outlined"
                        placeholder={!selectedOptions.length ? placeholder : ''}
                        onChange={(event) => { setSearch(event.target.value) }}
                        onKeyDown={event => {
                            if (event.key === 'Enter') {
                                searchValues(params.inputProps.value);
                                setSearch('');
                            }
                        }}
                        onBlur={() => setSearch('')}
                        inputProps={{
                            ...params.inputProps,
                            value: search,
                        }}
                    />
                )
            }}
            renderTags={(params) => {
                if (selectedOptions.length === 1)
                    return (
                        <div style={{ display: 'flex', alignItems: 'center', padding: '5px 10px', maxHeight: 29, backgroundColor: '#3966D0', color: '#fff', fontFamily: 'Poppins', fontSize: 15 }}>
                            <div>{selectedOptions[0].value}</div>
                            <IconButton
                                onMouseDown={(event) => {
                                    event.stopPropagation();
                                    let selected = [...selectedOptions];
                                    selected.splice(0, 1);
                                    setSelectedOptions(selected);
                                }}
                                style={{ marginLeft: 10 }}>
                                <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.425781 7.37012C0.186523 7.60938 0.181641 8.03418 0.430664 8.2832C0.68457 8.53223 1.10938 8.52734 1.34375 8.29297L4.23438 5.40234L7.12012 8.28809C7.36426 8.53223 7.78418 8.53223 8.0332 8.2832C8.28223 8.0293 8.28223 7.61426 8.03809 7.37012L5.15234 4.48438L8.03809 1.59375C8.28223 1.34961 8.28711 0.929688 8.0332 0.680664C7.78418 0.431641 7.36426 0.431641 7.12012 0.675781L4.23438 3.56152L1.34375 0.675781C1.10938 0.436523 0.679688 0.426758 0.430664 0.680664C0.181641 0.929688 0.186523 1.35938 0.425781 1.59375L3.31152 4.48438L0.425781 7.37012Z" fill="white" />
                                </svg>
                            </IconButton>
                        </div>
                    )
                if (selectedOptions.length > 1)
                    return (
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', maxHeight: 29 }}>
                            <div style={{ padding: '5px 10px', backgroundColor: '#3966D0', color: '#fff', fontFamily: 'Poppins', fontSize: 15, marginRight: 10 }}>{selectedOptions[0].value}</div>
                            <div style={{ padding: '5px 10px', backgroundColor: '#3966D0', color: '#fff', fontFamily: 'Poppins', fontSize: 15 }}>+{selectedOptions.length - 1}</div>
                        </div>
                    )
            }}
            renderOption={(params) => {
                const checked = selectedOptions.map(e => e.value).indexOf(params.value) !== -1;
                return (
                    <Box display='flex' flexDirection='row' alignItems='center'>
                        <Checkbox
                            style={{ color: '#6084D9', marginRight: 10 }}
                            checked={checked}
                            onChange={(event) => {
                                if (event.target.checked) {
                                    setSelectedOptions([...selectedOptions, { value: params.value, label: params.value }]);
                                }
                                else {
                                    const i = selectedOptions.map(e => e.value).indexOf(params.value);
                                    let newSelectedOptions = [...selectedOptions];
                                    newSelectedOptions.splice(i, 1);
                                    setSelectedOptions(newSelectedOptions);
                                }
                            }}
                        />
                        <div>{params.value}</div>
                    </Box>
                )
            }}
        />
    );
}

export default SelectMultipleSearch;
