import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getDataRows } from './data';

import apiData from '../../services/api_data';

export const getUserSandbox = createAsyncThunk(
    'sandbox/get',
    async () => {
        const data = await apiData.getUserSandbox();
        return data;
    }
);

export const deleteUserSandbox = createAsyncThunk(
    'sandbox/delete',
    async (_, { getState }) => {
        const { tableSelectionModel } = getState().sandbox;
        let removedIds = [];
        if (tableSelectionModel.length) {
            const responses = await Promise.all(tableSelectionModel.map(id => apiData.deleteUserSandbox(id)));
            removedIds = responses.map(r => r.stateMachine.dataId);
        }
        return removedIds;
    }
);

export const restoreDataset = createAsyncThunk(
    'sandbox/restore',
    async (_, { getState, dispatch }) => {
        const { sandboxedItems } = getState().sandbox;
        const toRestore = sandboxedItems.map(e => e.id);
        await Promise.all(toRestore.map(id => apiData.deleteUserSandbox(id)));

        dispatch(setSandboxedItems([]));
        dispatch(getDataRows());
    }
);

export const sandboxSlice = createSlice({
    name: 'sandboxSlice',
    initialState: {
        sandboxedItems: [],
        tableSelectionModel: [],
    },
    reducers: {
        setSandboxTableSelection: (state, action) => {
            state.tableSelectionModel = action.payload;
        },
        setSandboxedItems: (state, action) => {
            state.sandboxedItems = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserSandbox.rejected, (_, error) => console.error('REJECTED getUserSandbox: ', error))
            .addCase(deleteUserSandbox.rejected, (_, error) => console.error('REJECTED deleteUserSandbox: ', error))
            .addCase(getUserSandbox.fulfilled, (state, action) => {
                state.sandboxedItems = action.payload;
            })
            .addCase(deleteUserSandbox.fulfilled, (state, action) => {
                const removedIds = action.payload;
                state.sandboxedItems = state.sandboxedItems.filter(item => !removedIds.includes(item.id));
            });
    },
})

export const { setSandboxTableSelection, setSandboxedItems } = sandboxSlice.actions;

export default sandboxSlice.reducer;